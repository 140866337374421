import { ProductAttributeFilter, ProductFilters, numericFields, ProductStatus } from "../api/gql/types"
import { CategoryPageInfo } from "Pages/category"
import { flattenCategories } from "./categoryHelper"
import { defaultSortOrder } from "./sortHelper";

export const PAGE_SIZE = 24
const listProductStatuses: ProductStatus[] = [ProductStatus.AVAILABLE, ProductStatus.OUT_OF_STOCK, ProductStatus.SEASONAL]

function getAttributeFilters(filters: Webshop.IProductFilters): ProductAttributeFilter[] {
  return Object.values(filters.customAttributes || {})
    .map<ProductAttributeFilter>(attribute => ({
      attribute_id: attribute.id,
      options: attribute.selectedOptions || []
    }))
    .filter(attributeFilter => attributeFilter.options.length > 0)
}

export const getCategoryIdsFromFilters = (filters: Webshop.IProductFilters): string[] | null => {
  let categoryIds: string[] | null = null
  if (filters.selectedCategories && filters.selectedCategories.length > 0) {
      categoryIds = filters.selectedCategories.map(cat => cat.id)
  } else if (filters.category) {
      categoryIds = flattenCategories([filters.category], 3).map(cat => cat.id)
  }
  return categoryIds
}

export function filtersToGQLFilters(filters: Webshop.IProductFilters, pageInfo: CategoryPageInfo, postcode?: string | null): ProductFilters {
  const attributeFilters = getAttributeFilters(filters)

  if (pageInfo.type === 'brand') {
    attributeFilters.push({ attribute_id: pageInfo.brand.attribute.id, options: [pageInfo.brand.option.id] })
  }

  return {
    category: getCategoryIdsFromFilters(filters),
    numericRange: filters.priceMin && filters.priceMax ? [{
      field: numericFields.PRICE,
      from: filters.priceMin,
      to: filters.priceMax
    }] : undefined,
    is_special: filters.isDiscounted || pageInfo.type === 'discounted' || null,
    is_new: pageInfo.type === 'news' || null,
    attributes: attributeFilters.length > 0 ? attributeFilters : undefined,
    postcode: postcode && postcode.length === 4 ? Number(postcode) : null,
    productStatus: listProductStatuses
  }
}

export const filterDefaults = {
  selectedCategories: [],
  customAttributes: {},
  page: 1,
  pageSize: PAGE_SIZE,
  sortOrder: defaultSortOrder
}
