import { ProductOrder, ProductOrderField, OrderDirection, OrderedProductSort } from "Utils/api/gql/types"
import { CategoryPageType } from "Pages/category"
import { getSortOrderFromCookie } from "Utils/cookie";
import { IncomingMessage } from "http";

export const defaultSortOrder: ProductOrder = { field: ProductOrderField.PREFIXBOX_SCORE, direction: OrderDirection.DESC }

export type SortListItem<T extends ProductOrder | OrderedProductSort> = {
    title: string
    value: T
    hideFor?: Array<CategoryPageType>
    showFor?: Array<CategoryPageType>           // only show for these types
    defaultFor?: Array<CategoryPageType>
    strongDefaultFor?: Array<CategoryPageType>  // overrides cookie value
}

export const sortItems: Array<SortListItem<ProductOrder>> = [
    {
      title: 'Relevancia szerint',
      value: { field: ProductOrderField.SEARCH_SCORE, direction: OrderDirection.DESC },
      showFor: ['search'],
      defaultFor: ['search'],
      strongDefaultFor: ['search']
    },
    {
      title: 'Népszerűség szerint',
      value: { field: ProductOrderField.PREFIXBOX_SCORE, direction: OrderDirection.DESC },
      defaultFor: ['brand', 'category', 'discounted', 'news']
    },
    {
        title: 'Alapértelmezett',
        value: { field: ProductOrderField.SORT, direction: OrderDirection.ASC },
        defaultFor: ['promotion'],
        strongDefaultFor: ['promotion'],
        showFor: ['promotion'],
    },
    {
      title: 'Ár szerint növekvő',
      value: { field: ProductOrderField.PRICE, direction: OrderDirection.ASC }
    },
    {
      title: 'Ár szerint csökkenő',
      value: { field: ProductOrderField.PRICE, direction: OrderDirection.DESC }
    },
    {
      title: 'Egységár szerint növekvő',
      value: { field: ProductOrderField.UNIT_PRICE, direction: OrderDirection.ASC }
    },
    {
      title: 'Egységár szerint csökkenő',
      value: { field: ProductOrderField.UNIT_PRICE, direction: OrderDirection.DESC }
    },
    {
      title: 'Termék neve A-Z',
      value: { field: ProductOrderField.NAME, direction: OrderDirection.ASC }
    },
    {
      title: 'Termék neve Z-A',
      value: { field: ProductOrderField.NAME, direction: OrderDirection.DESC }
    }
  ]

export function areSortOrdersEqual(a: ProductOrder | OrderedProductSort, b: ProductOrder | OrderedProductSort) {
    return a.field === b.field && a.direction === b.direction
}

export function getAvailableSortOrdersForPageType(pageType: CategoryPageType): Array<SortListItem<ProductOrder>> {
    return sortItems.filter(item => (!item.hideFor || !item.hideFor.includes(pageType)) && (!item.showFor || item.showFor.includes(pageType)))
}

export function getDefaultSortOrderForPageType(pageType: CategoryPageType): SortListItem<ProductOrder> {
    const foundOrder = sortItems.find(item => !!item.defaultFor && item.defaultFor.includes(pageType))
    if (foundOrder) {
        return foundOrder
    }

    throw new Error(`No default SortOrder set for pageType: ${pageType}`)
}

export function getInitialSortOrderForPageType(pageType: CategoryPageType, req?: IncomingMessage): ProductOrder {
    const availableOrders = getAvailableSortOrdersForPageType(pageType)
    const strongDefault = availableOrders.find(item => !!item.strongDefaultFor && item.strongDefaultFor.includes(pageType))

    if (strongDefault) {
        return strongDefault.value
    }

    const cookieOrder = getSortOrderFromCookie(req)

    if (cookieOrder && availableOrders.find(item => areSortOrdersEqual(item.value, cookieOrder))) {
        return cookieOrder
    }

    return getDefaultSortOrderForPageType(pageType).value
}
