import React from 'react'
import { connect } from 'react-redux'

import Accordion from 'Components/Accordion'
import Checkbox from 'Components/Checkbox'
import Switch from 'Components/Switch'
import { Range } from 'react-input-range'

import cn from 'classnames'
import cg from 'Scss/app.scss'
import cl from './FilterPanel.scss'
import acc from 'Components/Accordion/Accordion.scss'
import plp from 'Scss/pages/ProductList.scss'
import btn from 'Components/Button/Button.scss'
import { RangeSliderWithTextInput } from './RangeSliderWithTextInput'
import { SVGArrow, SVGClose, SVGFilter, SVGArrowBack, SVGTrashSmall } from 'Components/SVGCollection'
import { getCategoryIcon } from 'Utils/helpers/productCategoryHelper'
import CustomAttributeInput from './CustomAttributeInput'
import { products_products_aggregations_categories } from 'Utils/api/gql/types'
import Button from 'Components/Button'
import { aggregateProductCountsInSubtreeAndFilterEmpty } from 'Utils/helpers/categoryHelper'

type OwnProps = {
  filters: Webshop.IProductFilters
  isRootCategory?: boolean
  priceRange: Range | null
  isFilteringActive: boolean
  filterViewMobile: boolean
  categoryAggregation: products_products_aggregations_categories[] | null | undefined
  categories: Array<Webshop.ICategory>
  customAttributes: Array<Webshop.IProductFilterAttribute>
  hideDiscountedSwitch: boolean
  onFilterChange: (filters: Webshop.IProductFilters) => void
  onCategoryChange: (newCategory: Webshop.ICategory) => void
  onToggleFilter: () => void
  onResetFilters: () => void
  onBackClick?: () => void
}

type ReduxProps = {
  session: Redux.IStoreSessionData
  isMobile: boolean
}

type PropType = OwnProps & ReduxProps

type StateType = {
  opened: { [key: string]: boolean }
}

class FilterPanel extends React.Component<PropType, StateType> {
  constructor(props: PropType) {
    super(props)
    this.state = {
      opened: {
        subCategories: true
      }
    }
  }

  handlePriceChange = (price: Range) => {
    const { onFilterChange } = this.props
    const newFilters = { ...this.props.filters, priceMin: price.min, priceMax: price.max }

    onFilterChange(newFilters)
  }

  handleOpenChange = (index: string) => (opened: boolean) => {
    const newValue = { ...this.state.opened, [index]: opened }
    this.setState({ opened: newValue })
  }

  handleCategoryChecked = (category: Webshop.ICategory) => () => {
    const { filters, categories, onFilterChange } = this.props
    const isOneLevel = this.isOneLevel()
    const newChecked = !this.isCategoryChecked(category.id)
    const selectedCategories = filters.selectedCategories || []
    const newSelectedCategories = newChecked
      ? [
          category,
          ...selectedCategories.filter(selectedCat => isOneLevel
              ? selectedCat.id !== category.id
              : selectedCat.id !== category.id && selectedCat.parent_id === category.parent_id && !categories.find(cat => cat.id === selectedCat.id)
            )
        ]
      : selectedCategories.filter(cat => cat.id !== category.id)

    const newFilters: Webshop.IProductFilters = {
      ...filters,
      selectedCategories: newSelectedCategories
    }

    onFilterChange(newFilters)
  }

  isCategoryChecked = (id: string) => (this.props.filters.selectedCategories || []).find(cat => id === cat.id) !== undefined

  isDiscountChecked = () => !!this.props.filters.isDiscounted

  handleDiscountChecked = () => {
    const { onFilterChange, filters } = this.props
    onFilterChange({
      ...filters,
      isDiscounted: !this.isDiscountChecked()
    })
  }

  handleCustomAttributeChange = (attribute: Webshop.IProductFilterAttribute, optionId: string) => {
    const { filters, onFilterChange } = this.props

    let selectedOptions = attribute.selectedOptions || []
    if (selectedOptions.includes(optionId)) {
      selectedOptions = selectedOptions.filter(id => id !== optionId)
    } else {
        selectedOptions = [...selectedOptions, optionId]
    }

    onFilterChange(
      {
        ...filters,
        customAttributes: {
          ...filters.customAttributes,
          [attribute.code]: { ...attribute, selectedOptions }
        }
      }
    )
  }

  get displayedCategories() {
    const aggregation = this.props.categoryAggregation
    if (!aggregation) {
      return this.props.categories
    }

    return aggregateProductCountsInSubtreeAndFilterEmpty(this.props.categories, aggregation)
  }

  opened = (id: string): boolean => this.state.opened[id] || false

  getAttribute = (attributeCode: string) => (this.props.filters.customAttributes || {})[attributeCode]

  isAttributeActive = (attributeCode: string) => {
    const attribute = this.getAttribute(attributeCode)
    if (attribute && attribute.selectedOptions && attribute.selectedOptions.length) {
      return true
    }
    return false
  }

  isOneLevel = (): boolean => {
    return this.props.categories.every(cat => cat.children.length === 0)
  }

  render() {
    const { filters, priceRange, isRootCategory, filterViewMobile } = this.props
    const isPriceFilterActive = !((!filters.priceMax && !filters.priceMin) || (priceRange && filters.priceMax === priceRange.max && filters.priceMin === priceRange.min))
    const isOneLevel = this.isOneLevel()
    const rangeValue: Range = priceRange !== null
      ? { min: filters.priceMin || priceRange.min, max: filters.priceMax || priceRange.max }
      : { min: 0, max: 0 }

    return (
      <div className={`${cl.filterPanel} ${filterViewMobile ? cl.open : ""}`}>

        <div className={cn(cl.mobileScroller)}>

          { this.displayedCategories.length > 0 &&
            (
              isRootCategory
                ? this.renderRootCategories()
                : isOneLevel
                  ? this.renderOneLevel()
                  : this.renderMultiLevel()
            )
          }

          {!this.props.hideDiscountedSwitch &&
            <>
              <Switch
                active={this.isDiscountChecked()}
                label="Ajánlatok"
                renderCheckbox={<input type="checkbox" checked={this.isDiscountChecked()} onChange={this.handleDiscountChecked} />}
              />
              <hr />
            </>
          }

          {priceRange !== null && priceRange.min !== priceRange.max &&
            <>
              <h3 className={cn({ [cg.activeText]: isPriceFilterActive })}>Ár</h3>
              <RangeSliderWithTextInput
                value={rangeValue}
                onChange={this.handlePriceChange}
                priceRange={priceRange}
                inputTimeout={1500}
              />
              <hr />
            </>
          }

          {this.props.customAttributes.map(attribute =>
            <Accordion
              isActive={this.isAttributeActive(attribute.code)}
              key={attribute.code}
              ariaId={attribute.code}
              ariaLevel={2}
              disabled={false}
              opened={this.opened(`custom-${attribute.code}`)}
              onOpenChange={this.handleOpenChange(`custom-${attribute.code}`)}
              renderHeader={<h2>{attribute.name}</h2>}
              useMaxHeight
            >
              <CustomAttributeInput
                onChange={this.handleCustomAttributeChange}
                attribute={this.getAttribute(attribute.code) || attribute}
              />
            </Accordion>
          )}
        </div>

        <div className={`${cl.mobileNavBar} ${cg.hideForLarge}`}>
          <div className={cn(cg.gridContainer, cl.gridContainer)}>
            <div className={cn(cg.gridX, cl.gridX, cg.alignMiddle)}>

              <div className={`${cg.cell} ${cg.auto} ${cl.linkBack}`}>
                <a onClick={this.props.onToggleFilter}>
                  <SVGArrowBack width={32} height={32} />
                </a>
                <span>Szűrések</span>
              </div>

              {this.props.isFilteringActive && <div className={cn(cg.cell, cg.shrink, cl.lastColls, cg.textRight)}>
                <a onClick={e => { e.preventDefault(); this.props.onResetFilters() }} className={cn(cl.pagelink)}>
                  <SVGTrashSmall width={20} height={20} /> <span>Összes törlése</span>
                </a>
              </div>}
            </div>
          </div>
        </div>

        <Button
            label="szűrések alkalmazása"
            className={cn(btn.secondary, cl.filterController__filterButton, cg.hideForLarge)}
            disabled={false}
            renderIcon={{ alignIcon: null, ariaLabel: 'ariaLabel', Icon: <SVGFilter width={36} height={36}  /> }}
            onClick={this.props.onToggleFilter}
        />
      </div>
    )
  }

  removeCategoryFilters = () => {
    this.props.onFilterChange({ ...this.props.filters, selectedCategories: [] })
  }

  renderRootCategories = () => (
    <Accordion
      ariaId='mainCategories'
      ariaLevel={2}
      disabled={false}
      opened={this.opened('subCategories')}
      onOpenChange={this.handleOpenChange('subCategories')}
      renderHeader={<h2>Kategóriák</h2>}
    >

        {this.displayedCategories.map(cat => {
          const icon = getCategoryIcon(cat)
          return (

              <div className={cn(plp.mainCategoryLink)} key={cat.id}>
                <a onClick={() => { this.props.onCategoryChange(cat) }}>
                  {icon}
                  <h3>{cat.name} ({cat.product_count})</h3>
                  <SVGArrow width={24} height={24} />
                </a>
              </div>

          )
        }
      )}
    </Accordion>
  )

  renderMultiLevel = () => (
    <Accordion
      isActive={this.props.filters.selectedCategories && !!this.props.filters.selectedCategories.length}
      ariaId='mainCategories'
      ariaLevel={2}
      disabled={false}
      opened={this.opened('subCategories')}
      onOpenChange={this.handleOpenChange('subCategories')}
      renderHeader={<h2>Kategóriák</h2>}
      useMaxHeight
    >
      <>
        {this.props.onBackClick &&
          <a className={cl.panelLinkBack} onClick={this.props.onBackClick}>
            <span aria-hidden="true">
              <SVGArrowBack width="22" height="22" />
            </span>
            <span>
              Vissza a főkategóriákhoz
            </span>
          </a>
        }
        {this.displayedCategories.map((outerCategory) => {
          const {id, name, product_count, children} = outerCategory
          const { selectedCategories } = this.props.filters
          return children.length > 0
          ? (
            <Accordion
              isActive={children.some(child => !!selectedCategories && selectedCategories.some(cat => cat.id === child.id))}
              key={`cat-${id}`}
              ariaId={`cat-${id}`}
              className={`${acc.subcategory}`}
              ariaLevel={3}
              disabled={false}
              opened={this.opened(`cat-${id}`)}
              onOpenChange={this.handleOpenChange(`cat-${id}`)}
              renderHeader={<h2>{name} ({product_count})</h2>}
              useMaxHeight
            >
            <ul>
              {children.map(cat => (
                <li key={cat.id}>
                  <Checkbox
                    checked={this.isCategoryChecked(cat.id)}
                    name={`cat-${cat.id}`}
                    onChange={this.handleCategoryChecked(cat)}
                    label={`${cat.name} (${cat.product_count})`}
                  />
                </li>
              ))}
            </ul>
            </Accordion>
          ) : (
            <div className={cn(plp.categoryCheck)} key={id}>
                <label>
                  <input type="checkbox" checked={this.isCategoryChecked(id)} onChange={this.handleCategoryChecked(outerCategory)} />
                  <h3>{name} ({product_count})</h3>
                  <span className={cn(plp.switchArrow)} >
                    <SVGArrow width={24} height={24} />
                  </span>
                  <span className={cn(plp.switchPaddle)} >
                    <SVGClose />
                  </span>
                </label>
            </div>
          )
        })}
          {this.props.filters.selectedCategories && this.props.filters.selectedCategories.length > 0 && <div className={cn(cg.textRight)}>
            <a className={cn(cl.pagelink)} onClick={this.removeCategoryFilters}>
              Összes törlése az Alkategóriákban
            </a>
          </div>}
      </>
    </Accordion>
  )

  renderOneLevel = () => (
      <Accordion
        isActive={this.props.filters.selectedCategories && !!this.props.filters.selectedCategories.length}
        ariaId='subcategories'
        ariaLevel={2}
        disabled={false}
        opened={this.opened('subCategories')}
        onOpenChange={this.handleOpenChange('subCategories')}
        renderHeader={<h2>Alkategóriák</h2>}
      >
        {this.props.onBackClick &&
        <div style={{marginBottom: '5px'}}>
          <a className={cl.panelLinkBack} onClick={this.props.onBackClick}>
            <span aria-hidden="true">
              <SVGArrowBack width="22" height="22" />
            </span>
            <span>
              Vissza a főkategóriákhoz
            </span>
          </a>
        </div>
        }
        <ul>
          {this.displayedCategories.map(cat => (
            <li key={cat.id}>
              <Checkbox
                checked={this.isCategoryChecked(cat.id)}
                name={`cat-${cat.id}`}
                onChange={this.handleCategoryChecked(cat)}
                label={`${cat.name} (${cat.product_count})`}
              />
            </li>
          ))}
        </ul>
        <div className={cn(cg.textRight)}>
          <a className={cn(cl.pagelink)} onClick={this.removeCategoryFilters}>
            Összes törlése az Alkategóriákban
          </a>
        </div>
      </Accordion>
  )

}

function mapToStateProps({ session, isMobile }: Redux.IReduxState): ReduxProps  {
  return { session, isMobile }
}

export default connect<ReduxProps, {}, OwnProps, Redux.IReduxState>(mapToStateProps)(FilterPanel);
