import React from 'react'

import { SVGSmallArrowDown } from 'Components/SVGCollection'

import cn from 'classnames'
import cg from 'Scss/app.scss'
import cl from './DropdownOrderBy.scss'
import { ProductOrder, OrderedProductSort } from 'Utils/api/gql/types'
import { CategoryPageType } from 'Pages/category';
import { getAvailableSortOrdersForPageType, areSortOrdersEqual, SortListItem } from 'Utils/helpers/sortHelper';



type StateType = {
  height: number | string,
  opened: boolean
}

type PropType<T extends ProductOrder | OrderedProductSort> = {
  items: SortListItem<T>[]
  disabled?: boolean,
  onChange: (value: T) => void,
  value: T
}

// TODO: ARIA
export default class DropdownOrderBy<T extends ProductOrder | OrderedProductSort> extends React.Component<PropType<T>, StateType> {
  foldableElement: HTMLUListElement | null = null

  constructor(props: PropType<T>) {
    super(props)
    this.state = {
      height: 0,
      opened: false
    }

    this.handleClick = this.handleClick.bind(this)
    this.setHeight = this.setHeight.bind(this)
  }

  componentDidMount() {
    this.setHeight()
    window.addEventListener('resize', this.setHeight)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setHeight)
  }

  setHeight() {
    this.setState({ height: 'auto' })

    const height = this.foldableElement && this.foldableElement.scrollHeight || 0

    this.setState({ height })
  }

  handleClick(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault()

    this.setState(prevState => ({
      opened: !prevState.opened,
    }))
  }

  render() {
    const { disabled } = this.props
    const { height, opened } = this.state

    const availableSortOrders = this.props.items
    const selected = availableSortOrders.find(item => areSortOrdersEqual(item.value, this.props.value)) || availableSortOrders[0]

    return (
      <div className={cn(cl.dropdownContainer, cl.dropdownOrderBy, opened && cl.dropdownContainerIsOpened)}>
        <div className={cl.dropdown__toggler}>
          <button
            className={cn(cg.gridX, cg.alignMiddle, cl.arrowContainer)}
            onClick={(e) => { this.handleClick(e) }}
            aria-label="Open"
            tabIndex={-1}
          >
            <span className={cn(cg.cell, cg.auto)}>
              {selected && selected.title}
            </span>

            <span className={cn(cg.cell, cg.shrink)} aria-hidden="true">
              <SVGSmallArrowDown width="14" height="14" />
            </span>
          </button>
        </div>

        <ul
          className={cn(cl.foldable, cl.dropdown__pane)}
          ref={(foldableElement) => { this.foldableElement = foldableElement }}
          style={{ height: opened || disabled ? height : 0 }}
          id="cb1-listbox"
          role="listbox"
          aria-label="Aria label"
        >
          {availableSortOrders.map(({title, value}) => (
            <li key={title} className={areSortOrdersEqual(value, this.props.value) ? cl.isSelected : undefined} onClick={(e) => { this.props.onChange(value); this.handleClick(e)}}>
              {title}
            </li>
          ))}
        </ul>

        {opened && <div className={cl.dropdown__overlay} onClick={this.handleClick} />}
      </div>
    )
  }
}
