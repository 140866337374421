import React from 'react'
import { SVGArrowBack } from 'Components/SVGCollection'

import cn from 'classnames'
import cg from 'Scss/app.scss'
import cl from './ProductListPageHeader.scss'
import { products_products_paginatorInfo } from 'Utils/api/gql/types'
import { CategoryPageInfo } from 'Pages/category'

type PropType = {
  pageInfo: CategoryPageInfo
  titleCategoryInfo?: string
  isFetching?: boolean
  paginatorInfo?: products_products_paginatorInfo
  onBackClick: () => void
  error?: string
}

export function titleFromPageInfo(pageInfo: CategoryPageInfo): string {
  switch (pageInfo.type) {
    case 'search':
      return `Keresés a '${pageInfo.searchTerm}' kifejezésre`
    case 'brand':
      return `${pageInfo.brand.option.label} termékek`
    case 'category':
    case 'promotion':
      return pageInfo.category.name
    case 'discounted':
      return 'Ajánlatok'
    case 'news':
      return 'Újdonságok'
    default:
      return 'Minden termék'
  }
}

export default class ProductListPageHeader extends React.Component<PropType> {
  render() {
    const { error, paginatorInfo, pageInfo, titleCategoryInfo } = this.props

    const productCountString = paginatorInfo ? ` (${paginatorInfo.total})` : null
    const errorString = error ? ' - problémák az internetkapcsolattal' : null
    const title = titleFromPageInfo(pageInfo)

    return (
      <div>
        <div className={cn(cg.gridX, cl.productListPage__header, cg.showForLarge)}>
          <div className={cn(cg.cell, cg.shrink)}>
            <a className={cl.linkBack} onClick={this.props.onBackClick}>
              <span className={cg.showForSr}>
                Vissza
              </span>

              <span aria-hidden="true">
                <SVGArrowBack width="32" height="32" />
              </span>
            </a>
          </div>
          <div className={cn(cg.cell, cg.auto)}>
            <h1 className={cl.h1}>
              {title}
              {(productCountString || errorString)
                ? <small className={cg.fontWeightMedium}>{titleCategoryInfo}{productCountString}{errorString}</small>
                : null
              }
            </h1>
          </div>
        </div>
      </div>
    )
  }
}
