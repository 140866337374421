import React from 'react'

import DropdownOrderBy from 'Components/Dropdown/DropdownOrderBy'
import { SVGFilter, SVGTrashSmall } from 'Components/SVGCollection'

import cn from 'classnames'
import cg from 'Scss/app.scss'
import cl from './ProductListPageFilterController.scss'
import { ProductOrder } from 'Utils/api/gql/types'
import { CategoryPageType } from 'Pages/category';
import { getAvailableSortOrdersForPageType } from 'Utils/helpers/sortHelper';

type PropType = {
  filtersActive: boolean
  pageType: CategoryPageType
  onSortChange: (value: ProductOrder) => void
  onResetFilters: () => void
  onToggleFilter: () => void
  sortValue: ProductOrder
}

export default class ProductListPageFilterController extends React.Component<PropType>  {
  render() {
    return (
      <div className={cn(cg.gridX, cg.alignMiddle, cl.productListPage__filterController)}>
        <div className={cn(cg.cell, cg.shrink)}>
          <button className={cn(cl.filterController__filterButton, { [cl.isActive]: this.props.filtersActive })} type="button" onClick={this.props.onToggleFilter}>
            <span className={cn(cg.gridX, cg.alignMiddle)}>
              <span className={cn(cg.cell, cg.shrink)}>
                <SVGFilter width={36} height={36}  />
              </span>

              <span className={cn(cg.cell, cg.auto)}>
                Szűrések
              </span>
            </span>
          </button>
        </div>

        {this.props.filtersActive && <div className={cn(cg.cell, cg.shrink, cg.showForMedium)}>
          <button className={cl.filterController__clearButton} type="button" onClick={this.props.onResetFilters}>
            <span className={cn(cg.gridX, cg.alignMiddle)}>
              <span className={cn(cg.cell, cg.shrink)}>
                <SVGTrashSmall width="16" height="16" />
              </span>

              <span className={cn(cg.cell, cg.auto)}>
                Összes törlése
              </span>
            </span>
          </button>
        </div>}

        <div className={cn(cg.cell, cg.auto)}>
          <div className={cn(cg.gridX, cg.alignRight)}>
            <div className={cn(cg.cell, cg.shrink)}>
              <DropdownOrderBy<ProductOrder>
                onChange={this.props.onSortChange}
                value={this.props.sortValue}
                items={getAvailableSortOrdersForPageType(this.props.pageType)}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
