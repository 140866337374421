import React from 'react'
import { connect, DispatchProp } from 'react-redux'

import { findCategoryByUrl } from 'Utils/helpers/categoryHelper'
import { AppContext } from 'Utils/withReduxStore'
import CategoryPageComponent from 'Components/CategoryPageComponent'
import { findBrandBySlug, AttributeOptionDescriptor } from 'Utils/helpers/productAttributeHelper'
import { Query } from 'react-apollo'
import { products, productsVariables, ProductOrderField, ProductOrder, OrderDirection } from 'Utils/api/gql/types'
import { productsQuery } from 'Utils/api/gql/queries/products'
import { filterDefaults, filtersToGQLFilters } from 'Utils/helpers/filterHelper'
import { isEqual } from 'Utils/helpers/misc'
import Error from './_error'
import { setSortOrderCookie } from 'Utils/cookie'
import { redirect } from 'Utils/helpers/routerHelper'
import { getInitialSortOrderForPageType, defaultSortOrder } from 'Utils/helpers/sortHelper'
import { isServer } from 'Utils/debugUtils';
import axios from 'axios'
import qs from 'querystringify';
import _app from './_app'
import gtmClientInstance, { ProductListGA4Type } from 'Utils/gtmClient';
import slugify from 'slugify';

type ReduxProps = {
  productCategories: Webshop.ICategory[]
  scheduledData: Redux.IScheduledData
  isMobile: boolean
  isOpenedCartPanel: boolean
  deliveryAddress?: Redux.IDeliveryAddress
} & DispatchProp

type StateType = {
  filters: Webshop.IProductFilters
  pageInfo: CategoryPageInfo
  ga4Info: ProductListGA4Type | undefined
}

export type CategoryPageInfo = ({
  type: 'category',
  category: Webshop.ICategory
} | {
  type: 'brand',
  brand: AttributeOptionDescriptor
} | {
  type: 'promotion',
  category: Webshop.ICategory
} | {
  type: 'search',
  searchTerm: string
} | {
  type: 'discounted'
} | {
  type: 'news'
} | {
  type: 'unknown'
}) & {
  sortOrder: ProductOrder
}

export type CategoryPageType = CategoryPageInfo['type']

type PageInfoProp = {
  pageInfo: CategoryPageInfo
}

type PropType = PageInfoProp & ReduxProps

const getGa4Info = (pageInfo: CategoryPageInfo): ProductListGA4Type | undefined => {
  if (pageInfo.type === "category") {
    return { listName: pageInfo.category.name, listId: pageInfo.category.url_key };
  }
  if (pageInfo.type === "search") {
    return { listName: `Keresés: ${pageInfo.searchTerm}`, listId: pageInfo.type };
  }
  if (pageInfo.type === "brand") {
    return { listName: `Márka: ${pageInfo.brand.option.label}`, listId: `brand-${slugify(pageInfo.brand.option.label.toLocaleLowerCase())}` };
  }
  if (pageInfo.type === "discounted") {
    return { listName: "Ajánlatok", listId: "ajanlatok" };
  }
  if (pageInfo.type === "news") {
    return { listName: "Újdonságok", listId: "ujdonsagok" };
  }
  if (pageInfo.type === "promotion") {
    return { listName: pageInfo.category.name, listId: pageInfo.category.url_key };
  }
  if (pageInfo.type === "unknown") {
    return { listName: "unknown", listId: "unknown" };
  }
  return undefined;
}

class CategoryPage extends React.Component<PropType, StateType> {
  state: StateType

  static async getInitialProps(ctx: AppContext): Promise<PageInfoProp> {
    const { query, initialState, req } = ctx
    const categories = ctx.reduxStore.getState().productCategories
    const promotions = ctx.reduxStore.getState().promotionCategories

    let productAttributes = initialState.scheduledData.productAttributes || null
    if (isServer()) {
      if (productAttributes === null && req.scheduledData) {
        productAttributes = req.scheduledData.productAttributes
      }
    } else {
      if (productAttributes === null) {
        const result = await axios.get('/local/productAttributes')
        productAttributes = result.data
      }
    }

    const searchTerm = query.searchTerm as string
    const brand = query.brandSlug ? findBrandBySlug(query.brandSlug as string, productAttributes) : undefined
    const promotionUrl = query.promotion_url as string

    let pageInfo: CategoryPageInfo = { type: 'unknown', sortOrder: defaultSortOrder }

    if (typeof query.path === 'string') {
      const path = `/${query.path}`
      let queryString = '';
      if (typeof req !== "undefined" && req.query) {
        try {
          queryString = qs.stringify(req.query, true)
        } catch {}
      }
      const foundCategory = findCategoryByUrl(path, categories)
      if (foundCategory) {
        pageInfo = { ...pageInfo, type: 'category', category: foundCategory }
      } else {
        let urlAliases = initialState.scheduledData.urlAliases || null
        if (isServer()) {
          if (urlAliases === null && req.scheduledData && req.scheduledData.urlAliases) {
            urlAliases = req.scheduledData.urlAliases
          }
          const foundAlias = urlAliases.find(alias => { return path.substr(path.length - 1, 1) !== '/' ? alias.source_url === path || alias.source_url === `${path}/` : alias.source_url === path })
          if (foundAlias) {
            redirect(`${foundAlias.target_url}${queryString}`, ctx.res)
            return { pageInfo }
          }
        } else {
          if (urlAliases === null) {
            const result = await axios.get(`/local/alias?url=${encodeURIComponent(path)}`)
            if (result.data && result.data.redirectTo && result.data.redirectTo !== null) {
              redirect(`${result.data.redirectTo}${queryString}`, ctx.res)
              return { pageInfo }
            }
          }
        }
      }
    }

    if (promotionUrl && promotions) {
      const promotion = promotions.find(p => p.url_key === promotionUrl)
      if (promotion) {
        // @ts-ignore
        pageInfo = {
          ...pageInfo,
          type: 'promotion',
          category: promotion
        }
      }
      if (!promotion) {
        redirect("/", ctx.res, 301);
      }
    }

    if (searchTerm) {
      pageInfo = { ...pageInfo, type: 'search', searchTerm: searchTerm.replace(/\+/g, ' ') }
    }

    if (brand) {
      pageInfo = { ...pageInfo, type: 'brand', brand }
    }

    if (ctx.asPath.indexOf('/ajanlatok') === 0) {
      pageInfo = { ...pageInfo, type: 'discounted' }
    }

    if (ctx.asPath.indexOf('/ujdonsagok') === 0) {
      pageInfo = { ...pageInfo, type: 'news' }
    }

    if (pageInfo.type === 'unknown') {
      if (ctx.res) {
        ctx.res.statusCode = 404
      }
      return { pageInfo }
    }

    pageInfo.sortOrder = getInitialSortOrderForPageType(pageInfo.type, ctx.req)

    return { pageInfo }
  }

  static getInitialStateFromPageInfo (pageInfo: CategoryPageInfo): StateType {
    return {
      filters: {
        ...filterDefaults,
        sortOrder: pageInfo.sortOrder,
        category: (pageInfo.type === 'category' || pageInfo.type === 'promotion') ? pageInfo.category : undefined
      },
      pageInfo,
      ga4Info: getGa4Info(pageInfo),
    }
  }

  static getKeyFromPageInfo (pageInfo: CategoryPageInfo): string {
    switch (pageInfo.type) {
      case 'brand':
        return `__BRAND_${pageInfo.brand.option.id}`
      case 'category':
        return `__CATEGORY_${pageInfo.category.id}`
      case 'promotion':
        return `__PROMOTION_${pageInfo.category.id}`
      case 'discounted':
        return `__DISCOUNTED_PRODUCTS`
      case 'news':
        return `__NEW_PRODUCTS`
      case 'search':
        return `__SEARCH_${pageInfo.searchTerm}`
      default:
        return ''
    }

  }

  static getDerivedStateFromProps (props: PropType, state: StateType): Partial<StateType> | null {
    if (!isEqual(props.pageInfo, state.pageInfo)) {
      return CategoryPage.getInitialStateFromPageInfo(props.pageInfo)
    }

    return null
  }

  constructor (props: PropType) {
    super(props)
    const { pageInfo } = props

    this.state = CategoryPage.getInitialStateFromPageInfo(pageInfo)
  }

  handleFilterChange = (filters: Webshop.IProductFilters, cb?: () => void) => {
    setSortOrderCookie(filters.sortOrder || this.props.pageInfo.sortOrder)
    this.setState({ filters }, cb)
  }

  render () {
    const { productCategories, isMobile, pageInfo } = this.props
    const { filters } = this.state

    if (pageInfo.type == 'unknown') {
      return <Error statusCode={404} />
    }

    const key = CategoryPage.getKeyFromPageInfo(pageInfo) + (this.props.deliveryAddress ? this.props.deliveryAddress.value.zip : '')
    const searchTerm = pageInfo.type === 'search'
      ? pageInfo.searchTerm
      : undefined

    const sortBy: ProductOrder = filters.sortOrder || pageInfo.sortOrder

    return (
      <div>
        <Query<products, productsVariables>
          query={productsQuery}
          variables={{
            count: filters.pageSize,
            page: 1,
            filters: filtersToGQLFilters(filters, pageInfo, this.props.deliveryAddress && this.props.deliveryAddress.value.zip),
            search: searchTerm,
            sortBy,
            pageURL: _app.getCurrentPath()
          }}
          notifyOnNetworkStatusChange={true}
          fetchPolicy='cache-first'
          partialRefetch={true}
        >
          {result => {
            return (
              <>
                <CategoryPageComponent
                  apolloResult={result}
                  pageInfo={pageInfo}
                  key={key}
                  filters={filters}
                  isMobile={isMobile}
                  productCategories={productCategories}
                  onFilterChange={this.handleFilterChange}
                  ga4Info={this.state.ga4Info!}
                />
              </>
            )
          }}
        </Query>
      </div>
    )
  }
}

const mapToStateProps = ({ productCategories, scheduledData, isMobile, isOpenedCartPanel, deliveryAddress }: Redux.IReduxState) => ({
  productCategories,
  scheduledData,
  isMobile,
  isOpenedCartPanel,
  deliveryAddress
})

export default connect(mapToStateProps)(CategoryPage)
