import React, { Fragment, ChangeEvent } from 'react'
import InputWrapper from 'Components/InputWrapper'
import InputRange, { InputRangeClassNames, Range } from 'react-input-range'
import debounce from 'lodash/debounce'

import cn from 'classnames'
import cg from 'Scss/app.scss'
import cl from './FilterPanel.scss'

import rir from 'node_modules/react-input-range/src/scss/input-range/input-range.scss'
import lrir from 'Scss/InputRange.scss'
import { Cancelable } from 'lodash'

type PropType = {
    priceRange: Range,
    value: Range,
    onChange: (price: Range) => void,
    inputTimeout?: number
}

type StateType = {
    editing: boolean,
    dragging: boolean,
    draftValue: Range
}

const classNames: InputRangeClassNames = {
    activeTrack: `${rir['input-range__track--active']} ${lrir['input-input-range__track--active']}`,
    disabledInputRange: `${rir['input-range--disabled']} ${lrir['input-range--disabled']}`,
    inputRange: `${rir['input-range']} ${lrir['input-range']}`,
    labelContainer: `${rir['input-range__label-container']} ${lrir['input-range__label-container']}`,
    maxLabel: `${rir['input-range__label-max']} ${lrir['input-range__label-max']}`,
    minLabel: `${rir['input-range__label-min']} ${lrir['input-range__label-min']}`,
    slider: `${rir['input-range__slider']} ${lrir['input-range__slider']}`,
    sliderContainer: `${rir['input-range__slider-container']} ${lrir['input-range__slider-container']}`,
    track: `${rir['input-range__track']} ${lrir['input-range__track']}`,
    valueLabel: `${rir['input-range__label-value']} ${lrir['input-range__label-value']}`
}

const constrainRange = (value: Range, constraint: Range): Range => ({
    min: Math.max(Math.min(value.min, value.max), constraint.min),
    max: Math.min(Math.max(value.max, value.min), constraint.max)
})

const rangesEqual = (a: Range, b: Range): boolean => a.max === b.max && a.min === b.min

export class RangeSliderWithTextInput extends React.Component<PropType, StateType> {
    debouncedCommit: (() => void) & Cancelable

    static getDerivedStateFromProps(props: PropType, state: StateType): Partial<StateType> | null {
        let { value } = props
        const { draftValue, editing, dragging } = state

        if (!editing && !dragging && !rangesEqual(draftValue, value)) {
            return {
                draftValue: value
            }
        }

        return null
    }

    constructor (props: PropType) {
        super(props)
        const { value, inputTimeout } = props

        this.debouncedCommit = debounce<() => void>(this.commit, inputTimeout)
        this.state = {
            editing: false,
            dragging: false,
            draftValue: value
        }
    }

    handlePriceInputChange = (minOrMax: string) => (e: ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.currentTarget.value, 10) || 0
        this.setState(state => ({ draftValue: { ...state.draftValue, [minOrMax]: value }, editing: true }), () => { this.debouncedCommit() })
    }

    handleBlur = () => { this.debouncedCommit.flush(); this.setState({ editing: false }) }

    commit = () => {
        const { onChange } = this.props
        const value = this.state.draftValue

        const constrainedValue = constrainRange(value, this.props.priceRange)
        onChange(constrainedValue)
    }

    handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.keyCode === 13) {
            e.preventDefault()
            this.handleBlur()
        }
    } 

    render () {
        const { priceRange, value } = this.props
        const { draftValue, dragging, editing } = this.state

        const rangeValue = constrainRange(dragging ? draftValue : value, priceRange)
        const textValue = (editing || dragging) ? draftValue : value

        return (
            <Fragment>
                <div className={cn(cg.gridX, cg.gridPaddingX, cg.alignMiddle, cl.priceInputs)}>
                    <div className={cn(cg.cell, cg.auto, cl.ieLarge)}>
                        <InputWrapper
                        name="minPrice"
                        error={null}
                        label="min"
                        hideLabel
                        >
                        <input
                            placeholder="130 Ft"
                            type="text"
                            className={cn(cl.small)}
                            value={textValue.min}
                            onChange={this.handlePriceInputChange('min')}
                            onBlur={this.handleBlur}
                            onKeyDown={this.handleKeyDown}
                        />
                        </InputWrapper>
                    </div>
                    <div className={cn(cg.cell, cg.shrink, cl.ieSmall)}>
                        <InputWrapper
                            name="maxPrice"
                            error={null}
                            label="max"
                            hideLabel
                            > - 
                        </InputWrapper>
                    </div>
                    <div className={cn(cg.cell, cg.auto, cl.ieLarge)}>
                        <InputWrapper
                        name="maxPrice"
                        error={null}
                        label="max"
                        hideLabel
                        >
                        <input
                            placeholder="1240 Ft"
                            type="text"
                            className={cn(cl.small)}
                            value={textValue.max}
                            onChange={this.handlePriceInputChange('max')}
                            onBlur={this.handleBlur}
                            onKeyDown={this.handleKeyDown}
                        />
                        </InputWrapper>
                    </div>
                </div>
                <div style={{marginTop: 40, marginBottom: 40, marginLeft: 12, marginRight: 16}}>
                    <InputRange
                        classNames={classNames}
                        maxValue={priceRange.max}
                        minValue={priceRange.min}
                        formatLabel={value => `${value} Ft`}
                        value={rangeValue}
                        onChange={value => { this.debouncedCommit.cancel(); this.setState({ draftValue: constrainRange(value as Range, priceRange), dragging: true })}}
                        onChangeComplete={() => {this.commit(); this.setState({ dragging: false })}} 
                    />
                </div>
            </Fragment>
        )
    }
}