import React, {ReactNode} from 'react'

import { SVGClose, SVGTick } from 'Components/SVGCollection'

import cn from 'classnames'
import cg from 'Scss/app.scss'
import cl from './Switch.scss'

type PropType = {
  label: string,
  active: boolean,
  renderCheckbox: ReactNode
}

/* TODO: Aria, https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/checkbox_role */
const Switch: React.SFC<PropType> = (props) => {
  const { label, renderCheckbox } = props

  return (
    <label className={cn(cg.gridX, cg.alignMiddle, cl.switch)}>
      <div className={cn(cg.cell, cg.auto, cl.label, { [cg.activeText]: props.active })}>
        {label}
      </div>

      {renderCheckbox}

      <div className={cn(cg.cell, cl.switchPaddleWrapper)}>
        <span
          className={cn(cl.switchPaddle)}
          role="checkbox"
          aria-checked="false"
          tabIndex={0}
        >
          <span className={cn(cl.switchPaddleIcon, cl.switchPaddleIconOff)}>
            <SVGClose />
          </span>

          <span className={cn(cl.switchPaddleIcon, cl.switchPaddleIconOn)}>
            <SVGTick width="100%" height="100%" />
          </span>
        </span>
      </div>
    </label>
  )
}

export default Switch
