import React from 'react'
import Checkbox from 'Components/Checkbox'
import { products_products_aggregations_attributes_options } from 'Utils/api/gql/types';

type PropType = {
    onChange: (attribute: Webshop.IProductAttribute, optionId: string) => void
    attribute: Webshop.IProductFilterAttribute
}

export default class CustomAttributeInput extends React.PureComponent<PropType> {
    render () {
        let { attribute: { selectedOptions, options } } = this.props
        options = (options || []).sort((a: products_products_aggregations_attributes_options,b: products_products_aggregations_attributes_options) => { return (a.position || 0) - (b.position || 0) })
        return (
            <ul>
                {(options || []).map(option =>
                    <li key={option.id}>
                        <Checkbox
                            name={option.id}
                            label={option.label}
                            checked={selectedOptions && selectedOptions.includes(option.id)}
                            onChange={() => { this.props.onChange(this.props.attribute, option.id) }}
                        />
                    </li>
                )}
            </ul>
        )
    }
}
