import React from 'react'
import Markdown from 'react-markdown'

import Breadcrumb from 'Components/Breadcrumb'
import Button from 'Components/Button'
import ProductList from 'Components/ProductList'
import ProductCard from 'Components/ProductCard'
import FilterPanel from 'Components/FilterPanel'
import { SVGArrowBack, SVGIconLink, SVGPlusOrange } from 'Components/SVGCollection'
import ProductListPageHeader from 'Components/ProductListPage/components/ProductListPageHeader'
import ProductListPageFilterController from 'Components/ProductListPage/components/ProductListPageFilterController'

import cn from 'classnames'
import cg from 'Scss/app.scss'
import cl from 'Scss/pages/ProductList.scss'
import cli from 'Scss/pages/Index.scss'
import btn from 'Components/Button/Button.scss'
import appBar from 'Components/AppBar/AppBar.scss'
import footer from 'Components/Footer/Footer.scss'

import { Range } from 'react-input-range'
import { mockListProduct } from 'Models/Product'
import { findCategory, breadcrumbFromPageInfo } from 'Utils/helpers/categoryHelper'
import { toggleCart } from 'Utils/redux/actions'
import { DispatchProp } from 'react-redux'
import CartPanel from './CartPanel'
import { connect } from 'react-redux'
import { products, products_products_aggregations_attributes, products_products_products, products_products_aggregations_categories, productsVariables, products_productListAds, ProductListAdType, ProductOrderField, OrderDirection, ProductListData } from 'Utils/api/gql/types'
import { QueryResult } from 'react-apollo'
import { PAGE_SIZE, filterDefaults } from 'Utils/helpers/filterHelper'
import { StickyContainerFix } from './StickyContainerFix'
import { NetworkStatus } from 'apollo-boost';
import { CategoryPageInfo } from 'Pages/category';
import { default as AppBar, ViewType } from './AppBar/AppBar';
import { titleFromPageInfo } from './ProductListPage/components/ProductListPageHeader/ProductListPageHeader';
import Footer from 'Components/Footer'
import Head from 'next/head';
import get from 'lodash/get'
import orderBy from 'lodash/orderBy'
import BodyStyler from './BodyStyler/BodyStyler';
import PageLink from 'Components/PageLink';
import uiEventEmitter, { UIEvents } from 'Utils/uiEventEmitter';
import { formatBreadcrumbForGtm } from './Breadcrumb/Breadcrumb';
import gtmClientInstance, { GtmProductListImpression, ProductListGA4Type } from 'Utils/gtmClient';
import { LinkRenderer } from 'Utils/helpers/markDownRenderers';
import Logger from 'Utils/Logging'

import cloneDeep from "../utils/helpers/cloneDeep";
const { Router } = require('Utils/routes')

import { Query } from 'react-apollo'
import { productsQuery } from 'Utils/api/gql/queries/products'
import ScrollableProductList from 'Components/ScrollableProductList'
import shuffle from 'lodash/shuffle'
import _app from 'Pages/_app'
import { prefixboxResult } from "Utils/helpers/prefixboxHelper"


type ReduxProps = {
    isOpenedCartPanel: boolean
}

type PropType = {
    apolloResult: QueryResult<products, productsVariables>
    pageInfo: CategoryPageInfo
    isMobile: boolean
    filters: Webshop.IProductFilters
    productCategories: Array<Webshop.ICategory>
    onFilterChange: (filters: Webshop.IProductFilters, cb?: () => void) => void
    ga4Info: ProductListGA4Type;
} & ReduxProps & DispatchProp

type StateType = {
    priceRange: Range | null
    attributes: Array<products_products_aggregations_attributes> | null
    categoryAggregations: Array<products_products_aggregations_categories>
    productCount: number | null
    isInitialLoad: boolean
    viewFilterMobile: boolean
    animInProgress: boolean
}

export type RenderableProductBox = products_products_products | products_productListAds

const MOCK_PRODUCT_COUNT = 12

class CategoryPageComponent extends React.Component<PropType, StateType> {
    static mockData: Array<products_products_products> = [...Array(MOCK_PRODUCT_COUNT)].map(() => mockListProduct())
    animFrame?: number
    prefixBoxAnalyticsRetryCount = 0
    prefixBoxAnalyticsSent = false
    prefixBoxAnalyticsTimeout: any = null

    state: StateType = {
        priceRange: null,
        attributes: null,
        productCount: null,
        isInitialLoad: true,
        animInProgress: false,
        viewFilterMobile: false,
        categoryAggregations: []
    }

    static getDerivedStateFromProps (props: PropType, state: StateType): Partial<StateType> | null {
        const { apolloResult: { data, loading, networkStatus } } = props

        // Query finished fetching and we want to reset pricerange and attributes (because category changed or whatever)
        if (
            (state.priceRange === null || state.attributes === null) &&
            networkStatus === NetworkStatus.ready && data && data.products && data.products.aggregations.max_price
        ) {
            return {
                priceRange: {
                    min: data.products.aggregations.min_price!,
                    max: data.products.aggregations.max_price
                },
                productCount: data.products.paginatorInfo.total,
                attributes: data.products.aggregations.attributes || [],
                categoryAggregations: data.products.aggregations.categories || [],
            }
        }

        if (loading && !state.animInProgress) {
            return {
                animInProgress: true
            }
        }

        return null
    }

    ensurePageProductConsistency = () => {
        const { filters, filters: { page }, apolloResult } = this.props

        // When resetting the filters to values that were already used
        // we have the data in cache. If show more was used, we have multiple pages
        // of data that Apollo gives us back without us requesting it.
        // (e.g. a query for page 1, count 24 might give back 96 products)
        // so we need to set our page state accordingly to avoid bugs with show more.
        if (
            apolloResult.networkStatus === NetworkStatus.ready &&
            apolloResult.data &&
            apolloResult.data.products &&
            apolloResult.data.products.products
        ) {
            const actualPage = Math.ceil(apolloResult.data.products.products.length / PAGE_SIZE)
            if (page !== actualPage) {
                this.props.onFilterChange({ ...filters, page: actualPage })
            }
        }

        // Visszafele iranyba is kell, ha nem cache-first a fetchPolicy
        // Ha cache-and-network, akkor eloszor lejon a cachebol a fetchmore-olt 85000 product
        // majd utana lefut a query es visszajon a szokasos 24
        // de addigra mar ott van a page a productok vegen szoval nem lehet fetchmoreolni.
        // Amugy igazabol cache-first lesz a nyero policy, de azert benne hagyom ezt
        // mert bantani nem bantja, viszont ha meg megis visszaallunk cache-and-networkre, akkor
        // kurva jo lesz ha ez itt van
        if (page && page > 1 && apolloResult.networkStatus == NetworkStatus.loading) {
            this.props.onFilterChange({ ...filters, page: 1 })
        }
    }

    componentDidMount () {
        this.ensurePageProductConsistency()
        this.sendPrefixboxAnalyticsIfNeeded()
    }

    componentWillUnmount() {
        if (this.prefixBoxAnalyticsTimeout !== null) {
            clearTimeout(this.prefixBoxAnalyticsTimeout)
        }
    }

    componentDidUpdate (prevProps: PropType) {
        this.ensurePageProductConsistency()

        if (this.props.apolloResult.loading && this.animFrame) {
            cancelAnimationFrame(this.animFrame)
            this.animFrame = undefined
        }

        if (!this.props.apolloResult.loading && prevProps.apolloResult.loading && this.state.animInProgress) {
            this.animFrame = requestAnimationFrame(() => { this.setState({ animInProgress: false }) })
        }

        if (this.props.pageInfo.type === 'search') {
            if (prevProps.pageInfo.type !== 'search' || prevProps.pageInfo.searchTerm !== this.props.pageInfo.searchTerm) {
                this.prefixBoxAnalyticsSent = false
            }

            this.sendPrefixboxAnalyticsIfNeeded()
        }
        if (this.props.apolloResult.loading !== prevProps.apolloResult.loading) {
            uiEventEmitter.emit(UIEvents.REPOSITION_BASKETBAR)
            if (!this.props.apolloResult.loading && this.props.apolloResult.data && this.props.apolloResult.data.products && this.props.apolloResult.data.products.products) {
                gtmClientInstance.sendViewItemListNew(this.props.apolloResult.data.products.products, this.props.ga4Info)
            }
        }
    }

    sendPrefixboxAnalyticsIfNeeded = () => {
        const total = get(this.props.apolloResult, 'data.products.paginatorInfo.total')
        if (!this.prefixBoxAnalyticsSent && this.props.pageInfo.type === 'search' && !this.props.apolloResult.loading && typeof total === 'number') {
            const inputParams = {
                pattern: this.props.pageInfo.searchTerm,
                count: total,
                page: 1,
            }

            Logger.debug('[PB] result', inputParams)
            prefixboxResult(inputParams)
            this.prefixBoxAnalyticsSent = true
        }
    }

    get attributes (): Array<products_products_aggregations_attributes> {
        if (this.props.pageInfo.type !== 'brand') {
            return this.state.attributes || []
        } else {
            return (this.state.attributes || []).filter(attribute => attribute.code !== 'brand')
        }
    }

    get isFilteringActive() {
        const { priceRange } = this.state
        const { filters } = this.props
        return !((!filters.customAttributes || !Object.keys(filters.customAttributes).length) &&
            ((!filters.priceMax && !filters.priceMin) || (priceRange && filters.priceMax === priceRange.max && filters.priceMin === priceRange.min)) &&
            (!filters.selectedCategories || !filters.selectedCategories.length) &&
            !filters.isDiscounted)

    }

    invalidateMetaData = () => {
        this.setState({ priceRange: null, attributes: null })
    }

    toggleFilterMobile = () => {
        this.setState({ viewFilterMobile: !this.state.viewFilterMobile })
    }

    handleFilterChange = (filters: Webshop.IProductFilters, cb?: () => void) => {
        this.setState({ isInitialLoad: true })
        this.props.onFilterChange({ ...this.props.filters, ...filters, page: 1 }, cb)
    }

    handleCategoryChange = (newCategory: Webshop.ICategory | undefined) => {
        this.props.onFilterChange({
            ...filterDefaults,
            category: newCategory
        })
        // We want the filtering options updated after a category change
        this.invalidateMetaData()
    }

    toggleBasketBar = () => {
        this.props.dispatch(toggleCart())
    }

    handleResetFilters = () => {
        this.setState({ isInitialLoad: true })
        this.props.onFilterChange({
            ...filterDefaults,
            priceMin: undefined,
            priceMax: undefined,
            category: this.props.filters.category
        })
    }

    handleShowMore = () => {
        const newPage = (this.props.filters.page || 1) + 1

        this.setState({ isInitialLoad: false })

        this.props.apolloResult.fetchMore({
            variables: {
                page: newPage,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult || fetchMoreResult.products.products && fetchMoreResult.products.products.length === 0) {
                    return prev
                }

                // We only increase the page number if we received data
                this.props.onFilterChange({ ...this.props.filters, page: newPage })
                return {
                    products: {
                        ...prev.products,
                        products: [
                            ...(prev.products.products || []),
                            ...(fetchMoreResult.products.products || [])
                        ],
                    },
                    productListAds: prev.productListAds
                }
            }
        })
    }

    isRootCategoryPage = () => {
        const { pageInfo } = this.props
        return pageInfo.type === 'search' || pageInfo.type === 'discounted' || pageInfo.type === 'news'
    }

    handleBackClick = () => {
        const { filters: { category }} = this.props
        if (category) {
            const parent = findCategory(category.parent_id, this.props.productCategories)
            if (this.isRootCategoryPage()) {
                this.handleFilterChange({ ...filterDefaults, category: parent, priceMin: undefined, priceMax: undefined, isDiscounted: undefined }, this.invalidateMetaData)
                return
            } else {
                if (parent) {
                    Router.pushRoute(parent.url)
                    return
                }
            }
        }

        Router.pushRoute('/')
    }

    prepareAds = (products: RenderableProductBox[], ads: products_productListAds[]) => {
        if (!ads) {
            return products
        }
        const prods = cloneDeep(products);
        const orderedAds = orderBy(ads, ['position'], ['asc']);
        orderedAds.filter(ad => ad.position <= prods.length).forEach((ad) => {
            prods.splice(Math.max(ad.position - 1, 0), 0, ad)
        })
        return prods
    }

    renderHead() {
        const { pageInfo } = this.props
        let title = ''
        let description = ''
        let canonical = null
        switch (pageInfo.type) {
            case 'category':
            case 'promotion':
                if (this.state.productCount && this.state.priceRange) {
                    title = `${pageInfo.category.name} Rendelés, Webáruház » ${this.state.productCount} termék ${this.state.priceRange.min} Ft-tól - ${pageInfo.category.name} Vásárlás, Árak`
                    description = pageInfo.category.description || `${pageInfo.category.name} Bolt: ${this.state.productCount} db ${this.state.priceRange.min} Ft-tól! ${pageInfo.category.name} Vásárlás, Árak » GRoby On-Line Shop - élelmiszer házhozszállítás, több mint 7000 féle élelmiszer, italáru és vegyiáru termék, raktárról, akár másnap otthonában.`
                    canonical = `${process.env.GWS_WEB_URL}${pageInfo.category.url}`
                }
                break
            case 'brand':
                    if (this.state.productCount && this.state.priceRange) {
                        title = `${pageInfo.brand.option.label} Rendelés, Webáruház » ${this.state.productCount} termék ${this.state.priceRange.min} Ft-tól - ${pageInfo.brand.option.label} Vásárlás, Árak`
                        description = `${pageInfo.brand.option.label} Bolt: ${this.state.productCount} db ${this.state.priceRange.min} Ft-tól! ${pageInfo.brand.option.label} Vásárlás, Árak » GRoby On-Line Shop - élelmiszer házhozszállítás, több mint 7000 féle élelmiszer, italáru és vegyiáru termék, raktárról, akár másnap otthonában.`
                    }
                    break
            case 'search':
                if (this.state.productCount && this.state.priceRange) {
                    title = `${pageInfo.searchTerm} Rendelés, Webáruház » ${this.state.productCount} termék ${this.state.priceRange.min} Ft-tól - ${pageInfo.searchTerm} Vásárlás, Árak`
                    description = `${pageInfo.searchTerm} Bolt: ${this.state.productCount} db ${this.state.priceRange.min} Ft-tól! ${pageInfo.searchTerm} Vásárlás, Árak » GRoby On-Line Shop - élelmiszer házhozszállítás, több mint 7000 féle élelmiszer, italáru és vegyiáru termék, raktárról, akár másnap otthonában.`
                }
                break
            case 'discounted':
            case 'news':
                if (this.state.productCount && this.state.priceRange) {
                    const name = pageInfo.type === 'discounted' ? 'Ajánlatok' : 'Újdonságok'
                    title = `${name} » ${this.state.productCount} termék ${this.state.priceRange.min} Ft-tól - ${name} Vásárlás, Árak`
                    description = `${name} Bolt: ${this.state.productCount} db ${this.state.priceRange.min} Ft-tól! ${name} Vásárlás, Árak » GRoby On-Line Shop - élelmiszer házhozszállítás, több mint 7000 féle élelmiszer, italáru és vegyiáru termék, raktárról, akár másnap otthonában.`
                }
                break
        }

        return (
            <Head>
                <title>{title}</title>
                <meta name="description" content={description} />
                {(canonical !== null) && <link rel="canonical" href={canonical}/>}
            </Head>
        )
    }

    renderProductList(products: Array<ProductListData>, inProgress = false) {
        const { isOpenedCartPanel } = this.props
        return (
          <>
            {!inProgress && <GtmProductListImpression products={products} list="Homepage Discounted Products" />}
            <ScrollableProductList isOpenedCartPanel={isOpenedCartPanel} isFetching={inProgress}>
              {shuffle(products).map((product, index) => (
                <div className={`${cg.cell}`} key={`discountedProducts${index}`}>
                  <ProductCard
                    product={product} view='grid' loading={inProgress}
                    analytics={{ position: index }}
                  />
                </div>
              ))}
            </ScrollableProductList>
          </>
        )
      }

    renderNoSearchResult = () => {
        return (
            <div className={cn(cg.gridX)} style={{ marginTop: 24 }}>
                    <div className={cn(cg.cell, cg.large12, cg.xlarge12)}>
                        <div className={cn(cl.noResoult)}>
                                <h3>Nincs találat</h3>
                                <img src="/static/empty-list_1.svg" width={230} height={170} alt="" />
                                <p>
                                    Kérjük ellenőrizd, hogy megfelelően írtad-e le keresett kifejezést. <br />
                                    Próbáld meg általánosabb kifejezéssel<br />
                                    vagy<br />
                                    módosíts a szűrési beállításokon!<br />
                                    <br />
                                    Bizonyos termékeket csak Budapestre és környékére szállítunk.<br />
                                    <PageLink target="_blank" toPage="/szallitas" className={cn(cg.linkWithIcon, cl.linkWithIcon)}>
                                        <span>További szállítási információk</span>
                                        <span className={cg.linkWithIcon__iconContainer}>
                                            <SVGIconLink width={15} height={15} />
                                        </span>
                                    </PageLink>
                                </p>
                            </div>
                    </div>

                    <div className={cn(cg.cell, cg.large12, cg.xlarge12)}>
                        <div className={cli.home}>
                            <div className={cli.contentPanel}>
                    <Query<products, productsVariables>
                        query={productsQuery}
                        partialRefetch={true}
                        variables={{
                            filters: { category: null, is_special: true, remove_homepage_prohibited: true },
                            page: 1,
                            count: 24,
                            sortBy: { direction: OrderDirection.DESC, field: ProductOrderField.SORT },
                            pageURL: _app.getCurrentPath()
                        }}
                    >{
                        ({ data, loading }) =>
                            (loading || data && data.products && data.products.products && data.products.products.length)
                            ? <>
                            <div className={cn(cli.homeHeading, cg.gridX)}>
                                <h3 className={cn(cg.cell, cg.auto)}>Ajánlatok</h3>
                                <div className={cn(cg.cell, cg.shrink)}>
                                <PageLink toPage="/ajanlatok">
                                    <span className={cn(cli.more)}>
                                    <span>Összes <span className={cn(cg.showForMedium)}>megtekintése</span></span>
                                    <SVGPlusOrange width={24} height={24} />
                                    </span>
                                </PageLink>
                                </div>
                            </div>
                            { !loading && data && data.products && data.products.products
                                ? this.renderProductList(data.products.products, false)
                                : this.renderProductList([...Array(4)].map(() => mockListProduct()), true)
                            }
                            </>
                        : null
                        }
                        </Query>
                        </div>
                        </div>
                    </div>
            </div>
        )
    }


    render () {
        const { productCategories, filters, apolloResult, pageInfo, isOpenedCartPanel } = this.props
        const { data, error, loading } = apolloResult
        const { viewFilterMobile, animInProgress, isInitialLoad } = this.state

        const products = data && data.products && data.products.products || []
        const productAds = data && data.productListAds && data.productListAds || []
        const paginatorInfo = data && data.products && data.products.paginatorInfo
        const categoryAggregation = this.state.categoryAggregations

        const breadcrumb = breadcrumbFromPageInfo(pageInfo, productCategories, filters)

        const isRootCategory = !filters.category
        const filterCategories = !isRootCategory
            ? (filters.category && filters.category.children) || []
            : productCategories
        const page = filters.page || 1

        const firstAnimatedId = isInitialLoad
            ? 0                             // On initial load (after a filter change) we want everything to animate, even if we display the remaining stale data from the previous query
            : !loading && animInProgress
                ? (page - 1) * PAGE_SIZE    // After loading stops, page will have incremented and we want the new data to finish animating
                : products.length           // While loading, we animate the mock products after the already fetched products

        const searchTerm = pageInfo.type === 'search'
            ? pageInfo.searchTerm
            : undefined

        let renderedData: RenderableProductBox[] = this.prepareAds(products, productAds);

        const footerClassName = this.state.viewFilterMobile ? footer.topIndex : ''

        const showNoSearchResult = pageInfo.type === 'search' && loading === false && products.length === 0 && this.isFilteringActive === false

        if (loading) {
            renderedData = [...products, ...CategoryPageComponent.mockData]
        }
        // renderedData = uniqWith(renderedData, (a, b) => a.id !== b.id)

        let titleCategoryInfo = ''
        if (pageInfo.type !== 'category' && pageInfo.type !== 'promotion' && this.props.filters.category) {
            titleCategoryInfo = ' - ' + this.props.filters.category.name
        }
        let title = titleFromPageInfo(pageInfo) + (paginatorInfo ? ` (${paginatorInfo.total || ''})` : '')

        const renderProducts = renderedData.map((data, index) => {
            if (data.__typename === "Product") {
                return (
                    <div className={cn(cg.cell)} key={`${data.id}-${index}`}>
                        <ProductCard
                            product={data}
                            view='grid'
                            loading={(animInProgress || !!error) && index >= firstAnimatedId}
                            analytics={pageInfo.type === 'search' ? { query: pageInfo.searchTerm, position: index } : { position: index }}
                            emphasized={false}
                        />
                    </div>
                )
            }
            if (data.__typename === "ProductListAd" && data.type === ProductListAdType.PRODUCT && data.product) {
                return (
                    <div className={cn(cg.cell)} key={`${data.product.id}-${index}`}>
                        <ProductCard
                            product={data.product}
                            view='grid'
                            loading={(animInProgress || !!error) && index >= firstAnimatedId}
                            analytics={pageInfo.type === 'search' ? { query: pageInfo.searchTerm, position: index } : { position: index }}
                            emphasized={true}
                        />
                    </div>
                )
            }
            if (data.__typename === "ProductListAd" && data.type === ProductListAdType.BANNER) {
                return (
                    <div className={cn(cg.cell, cl.bannerCell)} key={`banner-${index}`}>
                        <div className={cn(cl.banner)}>
                            {data.banner_url ? (<a href={data.banner_url}><img src={(this.props.isMobile ? data.image_url_mobile : data.image_url_desktop) || ""} alt="banner" /></a>)
                                             : (<img src={(this.props.isMobile ? data.image_url_mobile : data.image_url_desktop) || ""} alt="banner" />)
                            }
                        </div>
                    </div>
                )
            }
        })


        return (
<>
    {this.renderHead()}
    <AppBar
        viewType={ViewType.SubPage}
        pageTitle={title}
        initialSearchTerm={searchTerm}
        className={`${this.state.viewFilterMobile ? appBar.topIndex : ''}`}
        onBackClick={this.handleBackClick}
        pageType={pageInfo.type === 'search' ? 'Search' : 'List'}
        breadcrumb={formatBreadcrumbForGtm(breadcrumb)}
    />
    {this.state.viewFilterMobile && <BodyStyler />}


<div className={`${cg.contentHolder} ${cl.productListPage} ${this.state.viewFilterMobile ? cl.topIndex : ''}`}>
    <StickyContainerFix>
        <div className={`${cg.gridContainer} ${cg.panelLayout} ${isOpenedCartPanel === true ? cg.opened : ''}`}>
            <div className={cn(cg.cartOpener, appBar.cartOpener)}>
                <Button
                    label="Kosár tartalma"
                    className={cn(btn.hollow)}
                    disabled={false}
                    renderIcon={{ alignIcon: null, ariaLabel: 'ariaLabel', Icon: <SVGArrowBack width={24} height={24} /> }}
                    onClick={this.toggleBasketBar}
                />
            </div>
            <CartPanel />

            <div className={cn(cg.contentPanel, appBar.contentPanel, cg.mediumOrder1, cg.opened)}>
                {!showNoSearchResult && (
                    <div className={cg.showForLarge}>
                        <Breadcrumb data={breadcrumb} />
                        <ProductListPageHeader
                            titleCategoryInfo={titleCategoryInfo}
                            pageInfo={pageInfo}
                            paginatorInfo={paginatorInfo}
                            error={(error && error.message) || undefined}
                            onBackClick={this.handleBackClick}
                        />
                    </div>
                )}

                {showNoSearchResult ? this.renderNoSearchResult() : (
                <>
                    <ProductListPageFilterController
                        filtersActive={this.isFilteringActive}
                        onToggleFilter={this.toggleFilterMobile}
                        onResetFilters={this.handleResetFilters}
                        onSortChange={value => this.handleFilterChange({ sortOrder: value })}
                        sortValue={filters.sortOrder || filterDefaults.sortOrder}
                        pageType={pageInfo.type}
                    />

                    <div className={cn(cg.gridX)}>
                        <div className={cn(cg.cell, cg.large4, cg.xlarge3)}>
                            <FilterPanel
                                filters={filters}
                                priceRange={this.state.priceRange}
                                categories={filterCategories}
                                customAttributes={this.attributes}
                                categoryAggregation={categoryAggregation}
                                isRootCategory={isRootCategory}
                                isFilteringActive={this.isFilteringActive}
                                hideDiscountedSwitch={pageInfo.type === 'discounted'}
                                filterViewMobile={viewFilterMobile}
                                onToggleFilter={this.toggleFilterMobile}
                                onFilterChange={this.handleFilterChange}
                                onCategoryChange={this.handleCategoryChange}
                                onResetFilters={this.handleResetFilters}
                                onBackClick={this.isRootCategoryPage() ? this.handleBackClick : undefined}
                            />
                        </div>
                        <div className={cn(cg.cell, cg.large8, cg.xlarge9)}>
                            <GtmProductListImpression
                                products={products}
                                list={pageInfo.type === 'search' ? 'Search Results' : 'Product List'}
                            />
                            <ProductList
                                gridCols={{
                                    small: 2,
                                    medium: 3,
                                    large: isOpenedCartPanel ? 2 : 3,
                                    xlarge: isOpenedCartPanel ? 3 : 4
                                }}
                                view="grid"
                            >
                                {renderProducts}
                            </ProductList>
                            {data && data.products && products.length < data.products.paginatorInfo.total //@TODO backend nem jol mukodik --> && data.products.paginatorInfo.hasMorePages
                                ? <Button
                                    label="MUTASS TÖBBET"
                                    className={cn(btn.expanded, btn.secondary) }
                                    onClick={this.handleShowMore} disabled={loading || !!error}
                                />
                                : null
                            }

                            {renderedData.length === 0 &&
                                <div className={cn(cl.noResoult)}>
                                    <h3>Nincs találat</h3>
                                    <img src="/static/empty-list_1.svg" width={230} height={170} alt="" />
                                    <p>
                                        Kérjük ellenőrizd, hogy megfelelően írtad-e le keresett kifejezést. <br />
                                        Próbáld meg általánosabb kifejezéssel<br />
                                        vagy<br />
                                        módosíts a szűrési beállításokon!<br />
                                        <br />
                                        Bizonyos termékeket csak Budapestre és környékére szállítunk.<br />
                                        <PageLink target="_blank" toPage="/szallitas" className={cn(cg.linkWithIcon, cl.linkWithIcon)}>
                                            <span>További szállítási információk</span>
                                            <span className={cg.linkWithIcon__iconContainer}>
                                                <SVGIconLink width={15} height={15} />
                                            </span>
                                        </PageLink>
                                    </p>
                                </div>
                            }

                            {(pageInfo.type === 'category' || pageInfo.type === 'promotion') && pageInfo.category.content && pageInfo.category.content.length > 0 &&
                                <div className={cn(cl.content)}>
                                    <Markdown source={pageInfo.category.content} renderers={{ link: LinkRenderer }} />
                                </div>
                            }
                        </div>
                    </div>
                </>
                )}
            </div>
        </div>
    </StickyContainerFix>
    <Footer className={footerClassName} />
</div>
        </>
        )
    }
}

export default connect(({ isOpenedCartPanel }: Redux.IReduxState): ReduxProps => ({
    isOpenedCartPanel
}))(CategoryPageComponent)
